import { MainState } from ".";

export const initialState: MainState = {
  loading: false,
  form: {
    name: "",
    childName: "",
    dateOfBirth: "",
    email: "",
    mobileNumber: "",
  }, enquireForm: {
    name: "",
    email: "",
    mobileNumber: "",
    country: "",
    city: "",
    termAndCondition: false
  }
};
