import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

function Index() {
    return (
        <div>
            {" "}
            <Navbar />
            <section>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg1 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg2 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg3 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg4 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg5 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg23 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg7 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg8 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg9 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg10 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg11 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg12 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg13 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg14 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg15 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg16 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg17 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg18 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg19 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg20 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg21 copy.jpg" alt="" className="img-fluid" />
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-3">
                            <img src="images/newImages/gallery/fwd/pg22 copy.jpg" alt="" className="img-fluid" />
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Index;
