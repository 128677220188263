import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../types";


const selectDomain = (state: RootState) => {
  if (state && state.mainState) {
    return state.mainState;
  } else {
    return initialState;
  }
};

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectForm = createSelector(
  [selectDomain],
  (state) => state.form
);
export const selectEnquireForm = createSelector(
  [selectDomain],
  (state) => state.enquireForm
);
