/**
 * Combine all reducers in this file and export the combined reducers.
 */

 import { InjectedReducersType } from '../utils/types/injector-typings';

 import { combineReducers } from '@reduxjs/toolkit';
 
 /**
  * Merges the main reducer with the router state and dynamically injected reducers
  */
 export function createReducer(injectedReducers: InjectedReducersType = {}) {
   if (Object.keys(injectedReducers).length === 0) {
    //@ts-ignore
     return state => state;
   } else {
     return combineReducers({
       ...injectedReducers
     });
   }
 }