import React from 'react'

function Index() {
    return (
        <div><div className="counter-section  padding-bottom bg_img">
            <div className="section-header">
                <h3 className="title my-3 text-center pb-4" style={{ color: "#92278f", textTransform: "none" }}>Get Started in 8 Weeks</h3>
            </div>
            <div className="">
                <div className="row justify-content-center mb-30-none px-2">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/search_7928576.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">
                                    <h2 className="title">Week 0</h2>

                                </div>
                                <span className="cate">Enquiry</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/marketing_2173086.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">

                                    <h2 className="title">Week 1</h2>
                                </div>

                                <span className="cate">Meet Us</span>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/map_854878.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">

                                    <h2 className="title">Week 3</h2>
                                </div>
                                <span className="cate">Finalize the Location</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/contract_3862009.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">

                                    <h2 className="title">Week 4</h2>
                                </div>
                                <span className="cate">Signing the Agreement</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/goal_10356028.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">

                                    <h2 className="title">Week 6</h2>
                                </div>
                                <span className="cate">Setting up Preschool</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/classroom.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">

                                    <h2 className="title">Week 7</h2>
                                </div>
                                <span className="cate">Teacher's Training</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="counter-item round-style">
                            <div className="counter-thumb">
                                <img src="images/newImages/gallery/franchise/rocket_3363001.png" alt="counter"
                                    className='img-fluid' />
                            </div>
                            <div className="counter-content">
                                <div className="counter-header">

                                    <h2 className="title">Week 8</h2>
                                </div>
                                <span className="cate">Commencement of <br /> Preschool</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Index