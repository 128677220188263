import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "mainState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `form.${action.payload.key}`,
        action.payload.value
      );
    },

    clearFrom: (state) => {
      state.form.name = "";
      state.form.childName = "";
      state.form.dateOfBirth = "";
      state.form.email = "";
      state.form.mobileNumber = "";
    },
    doSendEmail: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => { },
    doSendEmailEnquire: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => { },
    updateEnquireFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `enquireForm.${action.payload.key}`,
        action.payload.value
      );
    },

    clearEnquireFrom: (state) => {
      state.enquireForm.name = "";
      state.enquireForm.city = "";
      state.enquireForm.country = "";
      state.enquireForm.email = "";
      state.enquireForm.mobileNumber = "";
      state.enquireForm.termAndCondition = false;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
