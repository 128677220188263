import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectLoading, selectEnquireForm } from "../../Redux/selector";
import { toast } from "react-toastify";
import { actions } from "../../Redux/slice";

function Index() {
    const dispatch = useDispatch();
    const form = useSelector(selectEnquireForm);
    const loading = useSelector(SelectLoading);

    const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        dispatch(actions.updateEnquireFormValue({ key: name, value: value }));
    };
    const handleEmailFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        dispatch(
            actions.updateEnquireFormValue({ key: name, value: value.toLowerCase() })
        );
    };
    const handleCheck = (evt: any) => {

        dispatch(
            actions.updateEnquireFormValue({ key: 'termAndCondition', value: evt.target.checked ? true : false })
        );
    };
    const handleSubmit = () => {
        dispatch(
            actions.updateEnquireFormValue({
                key: "email",
                value: form?.email?.trim(),
            })
        );

        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (form.email.length > 0) {
            if (!regex.test(form.email)) {
                toast.error("please enter valid email");
                return;
            }
        }
        dispatch(
            actions.doSendEmailEnquire({
                callback() {
                    dispatch(actions.clearEnquireFrom());
                },
            })
        );
    };
    return (
        <div>
            {" "}
            <section className="contact-section mt-4  ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="about-content">
                                <div className="section-header left-style mb-olpo">
                                    <h3 className="title" style={{ color: "#92278f" }}>
                                        FRANCHISE
                                    </h3>

                                    <p>
                                        Join our successful educational franchise network and make a
                                        lasting impact in your community. Benefit from our proven
                                        curriculum, comprehensive training, ongoing support, and a
                                        trusted brand reputation.
                                    </p>
                                    <p className="mt-1">
                                        Together, we can provide high-quality early childhood
                                        education, shaping young minds and building a brighter
                                        future.
                                    </p>
                                    <p className="mt-1">
                                        Take the next step towards owning your own kindergarten
                                        franchise and join our esteemed network of passionate
                                        educators.
                                    </p>
                                    <div>
                                        <img
                                            src="images/newImages/landingpage/6217503-removebg-preview.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div
                                className="contact-form-area"
                                style={{ borderRadius: "25px" }}
                            >
                                <h4 className="title">ENQUIRE NOW</h4>
                                <form className="contact-form" >
                                    <div className="form-group w-100">
                                        <input
                                            type="text"
                                            placeholder="Full Name"
                                            name="name"
                                            value={form.name}
                                            onChange={handleFieldChange}
                                            style={{ borderRadius: "25px" }}
                                        />
                                    </div>
                                    <div className="form-group w-100">
                                        <input
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                            value={form.email}
                                            onChange={handleEmailFieldChange}
                                            style={{ borderRadius: "25px" }}
                                        />
                                    </div>
                                    <div className="form-group w-100">
                                        <input
                                            type="number"
                                            placeholder="Phone"
                                            name="mobileNumber"
                                            value={form.mobileNumber}
                                            onChange={handleFieldChange}
                                            style={{ borderRadius: "25px" }}
                                        />
                                    </div>
                                    <div className="form-group w-100">
                                        <input
                                            type="text"
                                            placeholder="Enter Country"
                                            name="country"
                                            value={form.country}
                                            onChange={handleFieldChange}
                                            style={{ borderRadius: "25px" }}
                                        />
                                    </div>
                                    <div className="form-group w-100">
                                        <input
                                            type="text"
                                            placeholder="Enter City"
                                            name="city"
                                            value={form.city}
                                            onChange={handleFieldChange}
                                            style={{ borderRadius: "25px" }}
                                        />
                                    </div>
                                    <div className="form-group row  w-100 d-flex justify-content-between">
                                        <div className="col-1">
                                            <input
                                                className="pointer mt-0 "
                                                type="checkbox"
                                                id="check1"
                                                name="termAndCondition"
                                                defaultChecked={form.termAndCondition}
                                                checked={form.termAndCondition}
                                                onClick={(e: any) => handleCheck(e)}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </div>
                                        <div className="col-11">
                                            {" "}
                                            <label
                                                className="form-check-label  pointer fw-semibold"
                                                htmlFor="check1"
                                            >
                                                I Agree
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" w-100 text-center">
                                        <button
                                            className="custom-button w-50 "
                                            style={{ borderRadius: "30px !important" }}
                                            onClick={handleSubmit}
                                            type="button"
                                            disabled={loading}
                                        >
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Index;
