import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Testimonial from "../Components/Testimonial";
import WhyUs from "../Components/WhyUs";
import AllNeed from "../Components/AllNeed";
import Franchise from "../Components/Franchise";
import FranchiseProvide from "../Components/FranchiseProvide";
import GetStart from "../Components/GetStart";

function Index() {
  return (
    <div>
      {" "}
      <Navbar />
      <section>
        <Franchise />
      </section>
      <section>
        <AllNeed />
      </section>
      <section>
        <WhyUs />
      </section>
      <section>
        <FranchiseProvide />
      </section>
      <section>
        <GetStart />
      </section>
      <section>
        <Testimonial />
      </section>
      <Footer />
    </div>
  );
}

export default Index;
