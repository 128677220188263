import React from 'react'

function Index() {
    return (
        <div> <div className="counter-section padding-bottom bg_img">
            <div className="container">

                <div className="section-header">
                    <h3 className="title my-3 text-center pb-4" style={{ color: "#92278f", textTransform: "none" }}>What i Level Kids Franchise Provide
                    </h3>

                </div>
                <div className="row justify-content-center mb-30-none">
                    {data.map((data: any, index: number) => {
                        return <div className="col-lg-4 col-md-6 col-sm-10" key={index}>
                            <div className="counter-item">
                                <div className="counter-thumb">
                                    <img src={data.img} alt="counter"
                                        className="img-fluid" />
                                </div>
                                <div className="counter-content">
                                    <div className="counter-header">
                                        <h2 className="title">{data.text}</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                    })}


                </div>
                <div className="row justify-content-center mb-30-none mt-4">
                    {data1.map((data: any, index: number) => {
                        return <div className="col-lg-4 col-md-6 col-sm-10" key={index}>
                            <div className="counter-item">
                                <div className="counter-thumb">
                                    <img src={data.img} alt="counter"
                                        className="img-fluid" />
                                </div>
                                <div className="counter-content">
                                    <div className="counter-header">
                                        <h2 className="title">{data.text}</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                    })}


                </div>

            </div>
        </div></div>
    )
}

export default Index

const data = [
    { img: "images/newImages/gallery/franchise/teamwork.png", text: "Steam Activities" },
    { img: "images/newImages/gallery/franchise/classroom_3197877.png", text: "Infrastructure Design" },
    { img: "images/newImages/gallery/franchise/concept_1690967.png", text: "Think Room" },

]
const data1 = [

    { img: "images/newImages/gallery/franchise/refugee_7681410.png", text: "Adult Child Ratio" },
    { img: "images/newImages/gallery/franchise/teacher_8065344.png", text: "Trained Staff" },
    { img: "images/newImages/gallery/franchise/diet_706164.png", text: "100% Nutrition Food" },
]