import React from "react";

function Index() {
    return (
        <div>
            <header>
                <div className="header-top">
                    <div className="container">
                        <div className="header-top-area">
                            <ul className="left">
                                {/* <!-- <li>
                                <i class="far fa-clock"></i> 9:30am - 6:30pm Mon - Sun
                            </li> --> */}
                                <li>
                                    <a href="#">
                                        <i className="fas fa-phone-alt"></i>091771 33200
                                    </a>
                                </li>
                                <li>
                                    <i className="fas fa-map-marker-alt"></i>Hyderabad Telangana
                                </li>
                            </ul>
                            <ul className="social-icons">
                                <li>
                                    <a
                                        href="https://www.facebook.com/ILevelKids"
                                        target="_blank"
                                        className="facebook"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/ilevelkids?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" className="instagram">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <a href="/" style={{ padding: "7px 0" }}>
                                    {/* <!-- <img src="assets/images/logo/logo.png" alt="logo" /> --> */}
                                    <img
                                        src="images/newImages/i-Level-Kids-Logo-M.png"
                                        className=""
                                        alt="logo"
                                        height="75px"
                                    />
                                </a>
                            </div>
                            <div className="menu-area">
                                <ul className="menu">
                                    <li>
                                        <a href="/#top">

                                            Home
                                        </a>

                                    </li>
                                    <li>
                                        <a href="/#about">

                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#leadership">

                                            Leadership
                                        </a>

                                    </li>
                                    <li>
                                        <a href="/#academics">


                                            Academics
                                        </a>

                                    </li>
                                    <li>
                                        <a href="/#amenities">

                                            amenities
                                        </a>

                                    </li>
                                    <li>
                                        <a href="/#gallery">

                                            Gallery
                                        </a>

                                    </li>
                                    <li>
                                        <a href="/#addmission">
                                            Addmission
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/franchise" target="_blank">

                                            Franchise
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#contact">
                                            Contact
                                        </a>
                                    </li>
                                </ul>
                                <div className="header-bar d-lg-none">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="ellepsis-bar d-lg-none">
                                    <i className="fas fa-ellipsis-h"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </div >
    );
}

export default Index;
