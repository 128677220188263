import React from "react";

function Index() {
    return (
        <div className="counter-section padding-top padding-bottom bg_img">
            <div className="container">
                <div className="section-header">
                    <h3
                        className="title my-3 text-center pb-4"
                        style={{ color: "#92278f" }}
                    >
                        All You Need Is
                    </h3>
                </div>

                <div className="row justify-content-center mb-30-none ">
                    {data.map((data: any, index: number) => {
                        return <div className="col-lg-4 col-md-6 col-sm-10" key={index}>
                            <div className="counter-item">
                                <div className="counter-thumb">
                                    <img
                                        src={data.img}
                                        alt="counter"
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="counter-content">
                                    <div className="counter-content">
                                        <span className="cate">{data.cat1}</span>
                                        <div className="counter-header">
                                            <h2 className="title">{data.t1}</h2>&nbsp;
                                            <h2 className="title"> {data.t2}*</h2>
                                        </div>
                                        <span className="cate">{data.cat2}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </div>
    );
}

export default Index;

const data = [{
    img: "images/newImages/gif/newSchool.gif", t1: "1500", t2: "Sq. Ft.", cat1: "Approx", cat2: "Of Space"

},
{
    img: "images/newImages/gif/pesa.gif", t1: "15", t2: "Lacs*", cat1: "Upto", cat2: "Of Investment"

},
{
    img: "images/newImages/gif/passion.gif", t1: "Passion", t2: "", cat1: "Approx", cat2: "to nuture young minds"

}]