import React from 'react'

function Index() {
    return (
        <div> <section className="gallery-section padding-bottom pos-rel" id="gallery">

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <h3 className="title pb-3" style={{ color: "#92278f" }}>GALLERY</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb--40--50">
                    <div className="col-xl-4 col-md-6 col-sm-10">
                        <div className="gallery-item">
                            <div className="gallery-inner">
                                <div className="gallery-thumb">
                                    <a href="/gallery">
                                        <img src="images/newImages/gallery/fwd/pg18 copy.jpg" alt="gallery" />
                                    </a>
                                </div>
                                <div className="gallery-content">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-10">
                        <div className="gallery-item">
                            <div className="gallery-inner">
                                <div className="gallery-thumb">
                                    <a href="/gallery">
                                        <img src="images/newImages/gallery/fwd/pg16 copy.jpg" alt="gallery" />
                                    </a>
                                </div>
                                <div className="gallery-content">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-10">
                        <div className="gallery-item">
                            <div className="gallery-inner">
                                <div className="gallery-thumb">

                                    <a href="/gallery">
                                        <img src="images/newImages/gallery/fwd/pg21 copy.jpg" className="img-fluid" alt="gallery" />
                                    </a>
                                </div>
                                <div className="gallery-content">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-10">
                        <div className="gallery-item">
                            <div className="gallery-inner">
                                <div className="gallery-thumb">
                                    <a href="/gallery">
                                        <img src="images/newImages/gallery/fwd/pg2 copy.jpg" alt="gallery" />
                                    </a>
                                </div>
                                <div className="gallery-content">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-10">
                        <div className="gallery-item">
                            <div className="gallery-inner">
                                <div className="gallery-thumb">
                                    <a href="/gallery">
                                        <img src="images/newImages/gallery/fwd/pg1 copy.jpg" alt="gallery" />
                                    </a>
                                </div>
                                <div className="gallery-content">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-10">
                        <div className="gallery-item ">
                            <div className="gallery-inner">
                                <div className="gallery-thumb">
                                    <a href="/gallery">
                                        <img src="images/newImages/gallery/fwd/pg15 copy.jpg" alt="gallery" />
                                    </a>
                                </div>
                                <div className="gallery-content">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section></div>
    )
}

export default Index