import React from "react";
import AboutUs from "../Components/AboutUs";
import Academy from "../Components/AllNeed";
import Addmission from "../Components/Addmission";
import Amenities from "../Components/Amenities";
import Carousel from "../Components/Carousel";
import Footer from "../Components/Footer";
import LeaderShip from "../Components/LeaderShip";
import Navbar from "../Components/Navbar";
import Testimonial from "../Components/Testimonial";
import WhyUs from "../Components/WhyUs";
import Gallery from "../Components/Gallery";
import OurProgram from "../Components/OurProgram";

function Index() {
    return (
        <div>
            <Navbar />
            <section>
                <Carousel />
            </section>
            <section>
                <AboutUs />
            </section>
            <section>
                <LeaderShip />
            </section>
            <section>
                <OurProgram />
            </section>
            <section>
                <Amenities />
            </section>

            <section>
                <Gallery />
            </section>
            <section>
                <Addmission />
            </section>
            <Footer />
        </div>
    );
}

export default Index;
