import React from "react";

function Index() {
    return (
        <div>
            <section
                className="about-section pos-rel padding-bottom  "
                id="amenities"
            >
                <div className="container">
                    <div className="section-header">
                        <h3 className="title text-center pb-3" style={{ color: "#92278f" }}>
                            AMENITIES
                        </h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8"></div>
                    </div>
                    <div className="row justify-content-center mb-30-none -mx-10">
                        {data.map((data: any, index: number) => {
                            return (
                                <div className="col-md-6 col-lg-5 col-xl-3" key={index}>
                                    <div
                                        className="feature-item"
                                        style={{ borderRadius: "15px" }}
                                    >
                                        <div className="feature-thumb">
                                            <img src={data.img} className="ml--8" alt="feature" />
                                        </div>
                                        <div className="feature-content">
                                            <h5 className="title">{data.title}</h5>
                                            <p>{data.dis}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Index;

const data = [
    {
        img: "images/newImages/gif/paintng.gif",
        title: "Art & Crafts",
        dis: "Unleash creativity with our engaging art and craft activities, inspiring imagination and fostering self-expression.",
    },
    {
        img: "images/newImages/gif/music.gif",
        title: "Music",
        dis: "Interactive music lessons for kindergartners, fostering creativity, rhythm, and a lifelong love for musical expression.",
    },
    {
        img: "images/newImages/gif/dance.gif",
        title: "Dance",
        dis: "Experience the joy of movement as children discover the world of dance, expressing themselves through rhythm.",
    },
    {
        img: "images/newImages/gif/animation_lk7yuyha_small.gif",
        title: "Actvie Learning",
        dis: "Unlocking Creativity, Inspiring Innovation through Collaborative Problem-Solving, Critical Thinking and joyful Interactive learning.",
    },
    {
        img: "images/midmorning.gif",
        title: "Mid Morning Snacks",
        dis: "Nutrient-rich bites to curb hunger, enhance focus, and sustain energy during the mid-morning hours.",
    },
    {
        img: "images/playGround.gif",
        title: "Play Area",
        dis: "A Joyful Wonderland of Fun and Exploration, Where Children Laugh, Learn, and Make Lasting Memories.",
    },
    {
        img: "images/afterSchool_activites.gif",
        title: "After School Activities",
        dis: "Enriching Activities, Homework Support, and Exciting Adventures, Fostering Growth, Friendship, and Fun Beyond the Classroom",
    },
];
