import React from "react";

function Index() {
    return (
        <div
            className=""
            id="contact"
            style={{
                backgroundColor: " rgb(255 238 208)",
            }}

        >
            <div className="container pt-5">
                <div className="row mb--50">
                    <div className="col-6 col-lg-2 logo">
                        <div className="footer-widget">
                            <a href="/" style={{ padding: "7px 0" }}>
                                <img
                                    src="images/newImages/i-Level-Kids-Logo-L.png"
                                    className="img-fluid"
                                    alt="logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="footer-widget widget-about">
                            <h5 className="title">WORKING HOURS</h5>
                            <ul className="contact">
                                <li><i className="far fa-clock"></i> Office Hours</li>
                                <li> Mon-Fri: 09:00 am – 5:00 pm </li>
                                <li>Sat: 09:00 am - 1:00 pm </li>
                                <li><i className="far fa-clock"></i> School Timings </li>
                                <li> Mon - Sat: 09:00 am - 2:30 pm</li>

                            </ul>
                            <ul className="social-icons">
                                <li>
                                    <a
                                        href="https://www.facebook.com/ILevelKids"
                                        target="_blank"
                                        className="facebook"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/ilevelkids?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank" className="instagram">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="footer-widget widget-blog">
                            <h5 className="title">CONTACT US</h5>
                            <ul className="footer-blog me-0">
                                <li className="mb-0 pb-1">
                                    <div className="content ps-0">
                                        <span>6, 1/120/11, 6/1/120-9, Bapuji Nagar, MIGH Colony, Walker
                                            Town, Padmarao Nagar, Secunderabad, Telangana 500025</span>
                                    </div>
                                </li>
                                <li className="mb-0 pb-0">
                                    <div className="content ps-0">
                                        <a href="#" style={{ color: "#92278f" }}>
                                            <i className="fas fa-phone-alt"></i>
                                            +91-91771 33200
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div className="content ps-0">
                                        <a
                                            href="mailto:ilevelkids@gmail.com"
                                            style={{ color: "#92278f" }}
                                        >
                                            <i className="fa-regular fa-envelope"></i>{" "}
                                            ilevelkids@gmail.com
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="footer-widget widgt-form">
                            <h5 className="title">LOCATION</h5>
                            <div style={{ width: "100%" }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3201.0896874705936!2d78.50371914506879!3d17.423367250722073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9988d57d21c1%3A0x23631cbca650aa07!2sI%20Level%20Kids%20Play%20School!5e0!3m2!1sen!2sin!4v1691150377482!5m2!1sen!2sin"
                                    width="100%"
                                    height="250"
                                    style={{ border: "0" }}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom" style={{
                backgroundColor: " rgb(255 238 208)",
            }} >
                <div className="container">

                    <p>
                        &copy; 2023 All Rights Reserved. Designed by
                        <a href="#top">iLevel Kids</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Index;
