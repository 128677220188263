import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  sliceKey as MainSliceKey,
  reducer as MainReducer,
} from "./Redux/slice";
import { MainStateRepoSaga } from "./Redux/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import Home from "./Home";
import Gallery from "./Gallery";
import Franchise from "./Franchise";
function App() {
  useInjectReducer({ key: MainSliceKey, reducer: MainReducer });
  useInjectSaga({ key: MainSliceKey, saga: MainStateRepoSaga });
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/franchise" element={<Franchise />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
