import React from "react";

function Index() {
    return (
        <div>
            <section className=" bg_img h-100" data-background="" id="top">

                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner" data-interval="20">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-6"></div>

                                <div className="col-6 rightImageNew four">
                                    <img
                                        src="images/newImages/landingpage/re/Screenshot_2023-07-19_at_7.02.56_PM-removebg-preview.png"
                                        className="img-fluid" alt="" />
                                </div>

                            </div>
                            <img src="images/newImages/landingpage/newonelanding1.jpg" alt="" className="img-fluid w-100 "
                                style={{ borderRadius: "25px" }} />
                            <div className="custom-buttondiv">
                                <a href="#contact" className="custom-button1"><span>Get Contact Now</span></a>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-6"></div>
                                <div className="col-6 rightImage1 square">

                                </div>
                                <div className="col-6 rightImageNew1 three">
                                    <img src="images/newImages/landingpage/renew/af-removebg-preview.png" className="img-fluid" alt="" />
                                </div>


                            </div>
                            <img src="images/newImages/landingpage/ilevel2.jpg" alt="" className="img-fluid w-100 "
                                style={{ borderRadius: "25px" }} />
                            <div className="custom-buttondiv1"> <a href="#" className="custom-button1"><span>Get Started Now</span></a>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">

                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">

                        </a>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default Index;
