import React from "react";

function Index() {
    return (
        <div>
            {" "}
            <div
                className="teacher-section padding-bottom  pt-5 bg_img"
                data-background="images/teacher/teacher-bg.jpg"
                style={{ backgroundImage: `url(images/teacher/teacher-bg.jpg)` }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-header">
                                <h3
                                    className="title my-3 text-center pb-4"
                                    style={{ color: "#92278f" }}
                                >
                                    Testimonial
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-30-none justify-content-center">
                        <div className="col-lg-6">
                            <div className="teacher-item">
                                <div className="teacher-inner">
                                    <div className="teacher-thumb">
                                        <div className="thumb-inner">
                                            <a
                                                className="view-modal pointer"
                                                data-target="#quick_view1"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img src="images/newImages/testi1.jpeg" alt="teacher" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="teacher-content">
                                        <h6 className="title">
                                            <a href="">Nikitha</a>
                                        </h6>
                                        <span className="info"></span>
                                        <p>
                                            Great School!!!! <br />
                                            my sons are very happy to be at this. School Teacher's are
                                            very nice and helpful. This school is home for our family.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="teacher-item">
                                <div className="teacher-inner">
                                    <div className="teacher-thumb">
                                        <div className="thumb-inner">
                                            <a
                                                className="view-modal pointer"
                                                data-target="#quick_view1"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img src="images/newImages/testi2.jpeg" alt="teacher" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="teacher-content">
                                        <h6 className="title">
                                            <a href="">Shivam</a>
                                        </h6>
                                        <span className="info"></span>
                                        <p>
                                            The School shapes up children's lives in such an important
                                            way .The school is beyond amazing with focus of
                                            diversity,independence and confidence.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
