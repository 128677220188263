import React from "react";

function index() {
  return (
    <div>
      <div className="counter-section  padding-bottom bg_img">
        <div className="container">

          <div className="section-header">
            <h3 className="title my-3 text-center pb-4"
              style={{ color: "#92278f", textTransform: "none" }}
            >Why Choose iLevel
              Kids Franchise</h3>


          </div>
          <div className="row justify-content-center mb-30-none px-2">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="counter-item round-style">
                <div className="counter-thumb">
                  <img src="images/newImages/gallery/franchise/money-bag_2108625.png" alt="counter"
                    className='img-fluid' />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">40% IRR </h2>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="counter-item round-style">
                <div className="counter-thumb">
                  <img src="images/newImages/gallery/franchise/salary_3135706.png" alt="counter"
                    className='img-fluid' />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">Low Royalty</h2>

                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="counter-item round-style">
                <div className="counter-thumb">
                  <img src="images/newImages/gallery/franchise/school-bag_3429142.png" alt="counter"
                    className='img-fluid' />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">Addmissions</h2>

                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="counter-item round-style">
                <div className="counter-thumb">
                  <img src="images/newImages/gallery/franchise/digital-campaign.png" alt="counter"
                    className='img-fluid' />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">Marketing <br />Support</h2>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="counter-item round-style">
                <div className="counter-thumb">
                  <img src="images/newImages/gallery/franchise/customer-service_2706950.png"
                    alt="counter" className='img-fluid' />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">100% Sucess <br />Rate</h2>
                  </div>

                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-sm-6">
              <div className="counter-item round-style">
                <div className="counter-thumb">
                  <img src="images/newImages/gallery/franchise/resume.png" alt="counter"
                    className='img-fluid' />
                </div>
                <div className="counter-content">
                  <div className="counter-header">
                    <h2 className="title">Curriculum<br />Support</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
