import axios from "axios";
import Api from "./Api";

const SendEmail = async (data: any) => {
  return Api.post(`/student/email`, data);
};
const SendEnquiryEmail = async (data: any) => {
  return Api.post(`/student/enquiry-email`, data);
};

export {
  SendEmail,
  SendEnquiryEmail,
};
