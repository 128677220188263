import React from 'react'

function Index() {
    return (
        <div> <div className="container padding-bottom" id="academics">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-header">

                        <h3 className="title text-center pb-3" style={{ color: "#92278f" }}>Our Pre-School Programs</h3>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-30-none -mx-10">
                <div className="col-md-6 col-lg-5 col-xl-3 mt-5 mt-xl-0">
                    <div className="card feature-item h-100 mb-0 p-0">
                        <div style={{ height: "50%" }}>
                            <img src="images/a.jpeg" className="card-img-top img-fluid feature-item-image" alt="feature"
                                style={{ height: "100%", objectFit: "cover" }} />
                        </div>
                        <div className="card-body feature-content p-3">
                            <h5 className="card-title title">Play Group</h5>

                            <p>
                                Discovery and exploration language development, new-age skills and shaping scientific mindset.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-3 mt-5 mt-xl-0">
                    <div className="card feature-item h-100 mb-0 p-0">
                        <div style={{ height: "50%" }}>
                            <img src="images/b.jpeg" className="card-img-top img-fluid feature-item-image" alt="feature"
                                style={{ height: "100%", objectFit: "cover" }} />
                        </div>
                        <div className="card-body feature-content p-3">
                            <h5 className="title card-title">Nursery </h5>

                            <p>
                                Enhancing interaction, cultivability and improving imagination through art and music.
                            </p>
                        </div>
                    </div>
                    <div className="ChlidButton"><a href="#contact"><button>Enroll Your Child</button></a></div>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-3 mt-5 mt-xl-0">
                    <div className="card feature-item h-100 mb-0 p-0">
                        <div style={{ height: "50%" }}>
                            <img src="images/c.jpeg" className=" card-img-top img-fluid feature-item-image" alt="feature"
                                style={{ height: "100%", objectFit: "cover" }} />
                        </div>
                        <div className="card-body feature-content p-3">
                            <h5 className="card-title title">LKG</h5>

                            <p>
                                Age-appropriate learning and educational tools suited for individual pace.
                            </p>
                        </div>
                    </div>
                    <div className="partnerButton"><a href="/franchise">
                        <button>Become a Partner</button>
                    </a></div>
                </div>
                <div className="col-md-6 col-lg-5 col-xl-3 mt-5 mt-xl-0">
                    <div className="card feature-item h-100 mb-0 p-0">
                        <div style={{ height: "50% !important" }}>
                            <img src="images/d.jpeg" className="card-img-top img-fluid feature-item-image" alt="feature"

                                style={{ height: "100%", objectFit: "cover" }}
                            />
                        </div>
                        <div className="card-body feature-content p-3">
                            <h5 className="card-title title">UKG</h5>

                            <p>
                                Developing vocabulary attention, span reading and writing skills with innovative methods.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Index