import React from "react";


function Index() {
    return (
        <div className="padding-top about-bottom" id="about">
            <div className="container" id="about">
                <div className="square2 "></div>
                <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className=" pr-4 square">
                            <img
                                src="images/newImages/aboutPage.png"
                                alt="about"
                                className="img-fluid "
                            />
                        </div>
                        <div className="square21 d-block d-lg-none"></div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header left-style mb-olpo">
                                <h3 className="title pb-3" style={{ color: "#92278f" }}>
                                    ABOUT US
                                </h3>
                                <p>
                                    We believe that every child is a unique individual with limitless potential. Our dedicated team of passionate educators are committed to creating a safe, nurturing and stimulating environment where children can explore, discover and grow.
                                </p>
                            </div>
                            <div className="faq-wrapper mb--10">
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Vision
                                            <img
                                                src="images/newImages/gif/vision.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                            Empowering young minds with a holistic, innovative and inclusive education, fostering curiosity, creativity and a lifelong love of learning, while nurturing kindness, resilience and social responsibility.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Mission
                                            <img
                                                src="images/newImages/gif/mission.gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <ul className="ms-4">
                                            <li className="p-0" style={{ listStyle: "disk" }}>Create an environment where children are encouraged to embrace their individuality and explore their interests and passions freely.</li>
                                            <li className="p-0" style={{ listStyle: "disk" }}>To provide a school where children are motivated to face challenges, fostering their learning and personal growth</li>
                                            <li className="p-0" style={{ listStyle: "disk" }}>Where children are enveloped in an atmosphere of love and unwavering acceptance.</li>
                                            <li className="p-0" style={{ listStyle: "disk" }}>Where children can establish meaningful connections within their community.</li>
                                            <li className="p-0" style={{ listStyle: "disk" }}>To equip children with the skills and knowledge necessary to thrive in the future.
                                            </li>
                                            <li className="p-0" style={{ listStyle: "disk" }}>Where children experience both safety and respect.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="faq-item"
                                    style={{ borderRadius: "25px !important" }}
                                >
                                    <div className="faq-title">
                                        <span className="right-icon"></span>
                                        <h5
                                            className="title"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            Ideology
                                            <img
                                                src="images/newImages/gif/ideology .gif"
                                                className="img-fluid"
                                                style={{ maxWidth: "45px" }}
                                                alt=""
                                            />
                                        </h5>
                                    </div>
                                    <div className="faq-content">
                                        <p>
                                        Every child is unique and learn in their own way. we believe that all children have the potential to succeed and we provide a nurturing environment where they can explore learn and grow at their own pace
                                        </p>
                                    </div>
                                </div>
                                {/* <Accrodian></Accrodian> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
